export const preloader = ()=> {
    const preloader = document.querySelector('.preloader')
    if(preloader){
        setTimeout(() => {
            preloader.classList.add('opacity-0');
        }, 300);
        setTimeout(() => {
            preloader.classList.add('hidden');
        }, 1000);
    }
}