import {variables} from "../../vars.js";

export const popTariffs = ()=> {
    const popTariffs = document.querySelector('.tariffs__pop')
    const btnPopClose = document.querySelectorAll('.tariffs__pop-close')


    popTariffs?.addEventListener('click',(e)=> {
        if (e.target === popTariffs.querySelector('.container') || e.target === popTariffs) {
            popTariffs?.classList.remove('show')
            variables.bodySite?.classList.remove('overflow-hidden')
            var url = new URL(window.location.href);
            url.searchParams.delete('payment');
            history.replaceState({}, '', url);
        }
    })

    btnPopClose?.forEach((item,index)=> {
        item.addEventListener('click',(e)=> {
            popTariffs?.classList.remove('show')
            variables.bodySite?.classList.remove('overflow-hidden')
        })
    })
}