import {variables} from "../../vars.js";

export const popReviewsMore = ()=> {
    const allReadMore = document.querySelectorAll('.reviews__read-more');
    const popMore = document.querySelector('.reviews__pop-more');
    const allPopMoreWrappers = document.querySelectorAll('.reviews__pop-more-wrapper');
    const btnsPopMoreClose = document.querySelectorAll('.reviews__pop-more-close');

    popMore?.addEventListener('click',(e)=> {
        allPopMoreWrappers?.forEach((wrapper)=> {
            if (e.target === wrapper) {
                popMore?.classList.remove('opened')
                variables.bodySite?.classList.remove('overflow-hidden')
                allPopMoreWrappers.forEach((item)=> {
                    setTimeout(()=> {
                        item.style.display = 'none'
                    },500)
                })
            }
        })
    })

    allReadMore?.forEach((item,index)=> {
        item.addEventListener('click',()=> {
            allPopMoreWrappers[index].style.display = 'flex'
            popMore?.classList.add('opened')
            variables.bodySite.classList.add('overflow-hidden')
        })
    })

    btnsPopMoreClose?.forEach((item,index)=> {
        item.addEventListener('click',()=> {
            setTimeout(()=> {
                allPopMoreWrappers[index].style.display = 'none'
            },500)
            popMore?.classList.remove('opened')
            variables.bodySite.classList.remove('overflow-hidden')
        })
    })

}