import {variables} from "../../vars.js";

export const popReviewsOriginal = ()=> {
    const allBtns = document.querySelectorAll('.reviews__original')
    const popOriginal = document.querySelector('.reviews__pop-original')
    const popOriginalWrappers = document.querySelectorAll('.reviews__pop-original-wrapper')
    const popOriginalSlides = document.querySelectorAll('.reviews__pop-original-slide')
    const btnsPopOriginalClose = document.querySelectorAll('.reviews__pop-original-close')

    popOriginal?.addEventListener('click',(e)=> {
        popOriginalWrappers?.forEach((wrapper)=> {
            if (e.target === document.querySelector('.reviews__pop-original .container') || e.target === popOriginal) {
                popOriginal?.classList.remove('opened')
                variables.bodySite?.classList.remove('overflow-hidden')
                popOriginalWrappers?.forEach((item)=> {
                    setTimeout(()=> {
                        item.style.display = 'none'
                    },500)
                })
            }
        })
    })

    allBtns?.forEach((item,index)=> {
        item.addEventListener('click',(e)=> {
            e.preventDefault()
            popOriginalWrappers[index].style.display = 'flex'
            popOriginal?.classList.add('opened')
            variables.bodySite?.classList.add('overflow-hidden')


        })
    })

    $('.reviews__pop-original-wrapper').each(function () {
        const slider = $(this).find('.reviews__pop-original-swiper')
        const slides = $(this).find('.reviews__pop-original-slide')
        const btnPrev = $(this).find('.reviews__pop-swiper-prev')
        const btnNext = $(this).find('.reviews__pop-swiper-next')
        const btnPrevDesk = $(this).find('.reviews__pop-swiper-desk-prev')
        const btnNextDesk = $(this).find('.reviews__pop-swiper-desk-next')
        const scrollbar = $(this).find('.reviews__pop-swiper-scrollbar')

        if (window.innerWidth > 769) {
            if (slides.length > 2) {

                const swiperPopSlider = new Swiper(slider[0], {
                    autoHeight: true,
                    spaceBetween: 15,
                    slidesPerView: 1,
                    allowTouchMove:false,
                    // centeredSlides: true,
                    // loop: true,
                    navigation: {
                        nextEl: btnNext[0],
                        prevEl: btnPrev[0],
                    },
                    scrollbar: {
                        el: scrollbar[0],
                    },
                    breakpoints: {
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 30,
                            autoHeight: false,
                            allowTouchMove:false,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 60,
                            autoHeight: false,
                            allowTouchMove:false,
                            navigation: {
                                nextEl: btnNextDesk[0],
                                prevEl: btnPrevDesk[0],
                            }
                        },
                    }
                });

            } else  {

                btnNext[0].style.display = 'none'
                btnPrev[0].style.display = 'none'
                btnPrevDesk[0].style.display = 'none'
                btnNextDesk[0].style.display = 'none'
                scrollbar[0].style.display = 'none'
                slides.addClass('shrink-[1]')

            }
        } else  {

            const swiperPopSlider = new Swiper(slider[0], {
                autoHeight: true,
                spaceBetween: 15,
                slidesPerView: 1,
                allowTouchMove:false,
                // centeredSlides: true,
                // loop: true,
                navigation: {
                    nextEl: btnNext[0],
                    prevEl: btnPrev[0],
                },
                scrollbar: {
                    el: scrollbar[0],
                },
                breakpoints: {
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                        autoHeight: false,
                        allowTouchMove:false,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 60,
                        autoHeight: false,
                        allowTouchMove:false,
                        navigation: {
                            nextEl: btnNextDesk[0],
                            prevEl: btnPrevDesk[0],
                        }
                    },
                }
            });

        }


    })


    btnsPopOriginalClose?.forEach((item,index)=> {
        item.addEventListener('click',()=> {
            setTimeout(()=> {
                popOriginalWrappers[index].style.display = 'none'
            },500)
            popOriginal?.classList.remove('opened')
            variables.bodySite?.classList.remove('overflow-hidden')
        })
    })
}