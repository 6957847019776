import {variables} from "../../vars.js";

export const popLesson = ()=> {
    const openBtn = document.querySelector('.view__lesson')
    const popLesson = document.querySelector('.lesson__pop')
    const popLessonClose = document.querySelector('.lesson__pop-close')

    openBtn?.addEventListener('click',(e)=> {
        e.preventDefault()
        popLesson?.classList.add('active')
        variables.bodySite?.classList.add('overflow-hidden');
    })

    if (popLesson && popLesson.classList.contains('traps-pop')) {
        popLesson.classList.add('active');
        variables.bodySite?.classList.add('overflow-hidden');
    }

    popLesson?.addEventListener('click',(e)=> {
        const popNotice = popLesson.querySelectorAll('.wpcf7-not-valid-tip');
        const inputs = popLesson.querySelectorAll('.program__pop-input');

        if (e.target === popLesson.querySelector('.container') || e.target === popLesson) {
            if (!popLesson.classList.contains('traps-pop')) {
                popLesson?.classList.remove('active');
                variables.bodySite?.classList.remove('overflow-hidden');
                if (popNotice) {
                    popNotice.forEach((item)=> {
                        setTimeout(()=> {
                            item.style.display = 'none';
                        },500)
                    })
                }
                inputs.forEach((input)=> {
                    if (input.classList.contains('wpcf7-not-valid')) {
                        setTimeout(()=> {
                            input.classList.remove('wpcf7-not-valid')
                        },500)
                    }
                })
            }
        }
    })

    popLessonClose?.addEventListener('click',(e)=> {
        const popNotice = popLesson.querySelectorAll('.wpcf7-not-valid-tip');
        const inputs = popLesson.querySelectorAll('.program__pop-input');

        popLesson?.classList.remove('active');
        variables.bodySite?.classList.remove('overflow-hidden');
        if (popNotice) {
            popNotice.forEach((item)=> {
                setTimeout(()=> {
                    item.style.display = 'none';
                },500)
            })
        }
        inputs.forEach((input)=> {
            if (input.classList.contains('wpcf7-not-valid')) {
                setTimeout(()=> {
                    input.classList.remove('wpcf7-not-valid')
                },500)
            }
        })
    })
}