export const faqAccordeon = ()=> {
    const accordeonItem = document.querySelectorAll(".faq__item");

    accordeonItem?.forEach((currentTab) =>{
        let faqBtn = currentTab.querySelector(".faq__btn");
        let hiddenPart = currentTab.querySelector(".faq__hide");

        faqBtn?.addEventListener('click',(e) =>{
            faqBtn?.classList.toggle('active')
            let hiddenPartHeight = hiddenPart?.scrollHeight;
            currentTab?.classList.toggle("active");

            currentTab?.classList.contains("active") ? hiddenPart.style.maxHeight = hiddenPartHeight + 'px' : hiddenPart.style.maxHeight = null
        })
    })

}