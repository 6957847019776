export const imaskPhone = ()=> {
    // var element = document.getElementById('input-phone');
    var element = document.querySelectorAll('form .wpcf7-form-control-wrap input[type=tel]');
    if (element) {
        element?.forEach((item)=> {
            var maskOptions = {
                mask: '+{38}(000)000-00-00'
            };
            var mask = IMask(item, maskOptions);
        })

    }
}