export const mentorBlock = ()=> {
    const mentorBtn = document.querySelector('.mentor__btn')
    const mentorEditor = document.querySelector('.mentor__editor')

    mentorBtn?.addEventListener('click',(e)=> {
        e.preventDefault()
        mentorBtn.style.display = 'none';
        mentorEditor.style.display = 'block';
    })

    const mentorInst = document.querySelector(".mentor__inst > span");

    if (mentorInst) {
        mentorInst.onclick = function() {
            document.execCommand("copy");
        }

        mentorInst.addEventListener("copy", function(event) {
            event.preventDefault();
            if (event.clipboardData) {
                event.clipboardData.setData("text/plain", mentorInst.textContent);
            }
        });
    }

}