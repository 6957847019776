export const modulesMore = ()=> {
    const btnMore = document.querySelector('.modules__btn');
    const modules = document.querySelectorAll('.modules__item');

    btnMore?.addEventListener('click',()=> {
        btnMore.style.display = 'none';
        modules?.forEach((item)=> {
            item.style.display = 'flex';
        })
    })
}