import {variables} from "../vars.js";

export const mobileMenu = ()=> {
    const burgerBtn = document.querySelector('.burger')
    const closeBtn = document.querySelector('.mobile__menu-cross')
    const mobileTop = document.querySelector('.mobile__menu-top')
    const mobileUl = document.querySelector('.mobile__menu-ul')

    burgerBtn?.addEventListener('click',()=> {
        variables.mobileMenu?.classList.add('opened')
        variables.bodySite?.classList.add('overflow-hidden')
        setTimeout(()=> {
            mobileTop?.classList.add('active')
            mobileUl?.classList.add('active')
        },600)
    })

    closeBtn?.addEventListener('click',()=> {
        mobileTop?.classList.remove('active')
        mobileUl?.classList.remove('active')
        setTimeout(()=> {
            variables.mobileMenu?.classList.remove('opened')
            variables.bodySite?.classList.remove('overflow-hidden')
        },600)
    })

}