export const postsSlider = ()=> {
    // slider
    const swiperPosts = new Swiper('.posts__swiper', {
        autoHeight: true,
        spaceBetween: 15,
        navigation: {
            nextEl: '.posts__swiper-next',
            prevEl: '.posts__swiper-prev',
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 30,
                autoHeight: false,
            },
            1024: {
                slidesPerView: 2,
                spaceBetween: 30,
                autoHeight: false,
                navigation: {
                    nextEl: '.posts__swiper-desk-next',
                    prevEl: '.posts__swiper-desk-prev',
                }
            },
            1280: {
                autoHeight: false,
                slidesPerView: 3,
                spaceBetween: 30,
                navigation: {
                    nextEl: '.posts__swiper-desk-next',
                    prevEl: '.posts__swiper-desk-prev',
                }
            }
        }
    });
}