import {marqueeHero} from "./modules/pages/blocks/marquee.js";
import {mobileMenu} from "./modules/mobileMenu.js";
import {contactForm} from "./modules/contact-form.js";
import {headerFixed} from "./modules/header.js";
import {mentorBlock} from "./modules/pages/blocks/mentor-block.js";
import {anchorScroll} from "./modules/anchor-scroll.js";
import {reviewsSlider} from "./modules/sliders/reviews-slider.js";
import {popReviewsMore} from "./modules/pop-up/pop-reviews-more.js";
import {postsSlider} from "./modules/sliders/posts-slider.js";
import {popReviewsOriginal} from "./modules/pop-up/pop-reviews-original.js";
import {PagesAnimation} from "./modules/page-anim.js";
import {popAboutCourse} from "./modules/pop-up/pop-about-course.js";
import {popQuestion} from "./modules/pop-up/pop-question.js";
import {popTariffs} from "./modules/pop-up/pop-tariffs.js";
import {popPresent} from "./modules/pop-up/pop-present.js";
import {popDoubts} from "./modules/pop-up/pop-doubts.js";
import {faqAccordeon} from "./modules/pages/blocks/faq-block.js";
import {modulesMore} from "./modules/pages/blocks/modules-block.js";
import {preloader} from "./modules/preloader.js";
import {imaskPhone} from "./modules/imask-phone.js";
import {popLesson} from "./modules/pop-up/pop-lesson.js";

document.addEventListener("DOMContentLoaded", function (event) {
    headerFixed();
    mobileMenu();
    imaskPhone();

    // blocks start

    marqueeHero();

    mentorBlock();

    reviewsSlider();

    postsSlider();

    faqAccordeon();

    modulesMore();

    // pop-ups start

    popReviewsMore();

    popReviewsOriginal();

    popAboutCourse();

    popQuestion();

    popTariffs();

    popPresent();

    popDoubts();

    popLesson();

    // pop-ups end

    contactForm();

    preloader();

    anchorScroll();

    PagesAnimation();

});