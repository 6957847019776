import {variables} from "../vars.js";

export const headerFixed = ()=> {
    document.querySelector('.header__line')?.classList.remove('pined')

    if (variables.bodySite.classList.contains('error404')) {
        variables.header?.classList.add('black')
    }

    const dataSections = document.querySelectorAll('[data-color]')

    window.addEventListener('scroll',()=> {
        if (window.scrollY > 1) {
            document.querySelector('.header__line')?.classList.add('pined')
        } else {
            document.querySelector('.header__line')?.classList.remove('pined')
        }
        dataSections?.forEach((item)=> {
            if (window.scrollY + variables.header.offsetHeight >= item.offsetTop && window.scrollY  + variables.header.offsetHeight <= item.offsetTop + item.getBoundingClientRect().height) {
                variables.header?.classList.add(item.getAttribute('data-color'))
            } else {
                variables.header?.classList.remove(item.getAttribute('data-color'))
            }
        })
    })
    dataSections?.forEach((item)=> {
        if (window.scrollY  + variables.header.offsetHeight <= item.offsetTop + item.getBoundingClientRect().height) {
            variables.header?.classList.add(item.getAttribute('data-color'))
        } else {
            variables.header?.classList.remove(item.getAttribute('data-color'))
        }
    })
}