import {variables} from "../../vars.js";

export const popQuestion = ()=> {
    const btnPop = document.querySelectorAll('.question__btn');
    const popProgram = document.querySelector('.question__pop');
    const btnPopClose = document.querySelectorAll('.question__pop-close');
    const popThanks = document.querySelector('.question__pop-thanks');
    const popForm = document.querySelector('.question__pop-form');

    btnPop?.forEach((item)=> {
        item.addEventListener('click',(e)=> {
            e.preventDefault();
            popProgram?.classList.add('active');
            variables.bodySite?.classList.add('overflow-hidden');
        })
    })

    popProgram?.addEventListener('click',(e)=> {
        const popNotice = popProgram.querySelectorAll('.wpcf7-not-valid-tip');
        const inputs = popProgram.querySelectorAll('.program__pop-input');

        if (e.target === popProgram.querySelector('.container') || e.target === popProgram) {
            popProgram?.classList.remove('active');
            variables.bodySite?.classList.remove('overflow-hidden');
            // popThanks?.classList.remove('active');
            if (popThanks.classList.contains('active')) {
                setTimeout(()=> {
                    popThanks?.classList.remove('active')
                    popForm?.classList.remove('hide')
                    popProgram.style = 'overflow:auto!important;';
                    popProgram.querySelector('.container').style = 'height:auto;';
                    popForm.style.display = 'flex';
                },500)
            }
            if (popNotice) {
                popNotice.forEach((item)=> {
                    setTimeout(()=> {
                        item.style.display = 'none';
                    },500)
                })
            }
            inputs.forEach((input)=> {
                if (input.classList.contains('wpcf7-not-valid')) {
                    setTimeout(()=> {
                        input.classList.remove('wpcf7-not-valid')
                    },500)
                }
            })
        }
    })

    btnPopClose?.forEach((item)=> {
        item.addEventListener('click',(e)=> {
            const popNotice = document.querySelectorAll('.wpcf7-not-valid-tip');
            const inputs = popProgram.querySelectorAll('.program__pop-input');

            popProgram?.classList.remove('active');
            variables.bodySite?.classList.remove('overflow-hidden');
            // popThanks?.classList.remove('active');
            if (popThanks.classList.contains('active')) {
                setTimeout(()=> {
                    popThanks?.classList.remove('active')
                    popForm?.classList.remove('hide')
                    popProgram.style = 'overflow:auto!important;';
                    popProgram.querySelector('.container').style = 'height:auto;';
                    popForm.style.display = 'flex';
                },500)
            }
            if (popNotice) {
                popNotice.forEach((item)=> {
                    setTimeout(()=> {
                        item.style.display = 'none';
                    },500)
                })
            }
            inputs.forEach((input)=> {
                if (input.classList.contains('wpcf7-not-valid')) {
                    setTimeout(()=> {
                        input.classList.remove('wpcf7-not-valid')
                    },500)
                }
            })
        })
    })

    popProgram?.querySelector('form').addEventListener('wpcf7mailsent',()=>{
        popThanks?.classList.add('active');
        popProgram.style = 'overflow:hidden!important';
        popProgram.querySelector('.container').style = 'height:100%';
        popForm?.classList.add('hide');
        popForm.style.display = 'none';
    })

}