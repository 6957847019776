import {variables} from "../../vars.js";

export const popAboutCourse = ()=> {
    const recordPlayBtn = document.querySelector('.record__play')
    // const popAbout = document.querySelector('.about-course__pop')
    // const popAboutClose = document.querySelector('.about-course__pop-close')
    // const popVideo = popAbout?.querySelector('.about-course__pop iframe')
    const recordVideo = document.querySelector('.record__video')

    // playBtn?.addEventListener('click',(e)=> {
    //     popAbout?.classList.add('active')
    //     variables.bodySite?.classList.add('overflow-hidden')
    //     popVideo?.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*')
    // })
    recordPlayBtn?.addEventListener('click',(e)=> {
        recordVideo?.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*')
        recordVideo?.classList.add('active')
    })
    //
    // popAbout?.addEventListener('click',(e)=> {
    //     if (e.target === popAbout.querySelector('.container') || e.target === popAbout) {
    //         popAbout?.classList.remove('active')
    //         variables.bodySite?.classList.remove('overflow-hidden')
    //         popVideo?.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
    //     }
    // })
    //
    // popAboutClose?.addEventListener('click',(e)=> {
    //     popAbout?.classList.remove('active')
    //     variables.bodySite?.classList.remove('overflow-hidden')
    //     popVideo?.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
    // })


}