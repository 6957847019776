export const PagesAnimation = ()=> {

    if (document.querySelector('.header')) {
        const tlHeader = gsap.timeline();

        tlHeader.fromTo('.header__logo', {opacity: 0}, {opacity: 1, duration: 1}, .2)
            .fromTo('.header__menu li', {opacity: 0}, {opacity: 1, stagger: 0.2, duration: 1}, .2)
    }

    if (document.querySelector('.hero') || document.querySelector('.hero-about')) {
        const tl = gsap.timeline();
        tl.fromTo('.header__logo', {opacity: 0}, {opacity: 1, duration: 1}, .2)
            .fromTo('.header__menu li', {opacity: 0}, {opacity: 1, stagger: 0.2, duration: 1}, .2)
            .fromTo('.hero__title', {opacity: 0}, {opacity: 1, duration: 1}, .5)
            .fromTo('.hero__description > p', {yPercent: 100, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1}, 1)
            .fromTo('.hero__image', {opacity: 0}, {opacity: 1, duration: 1}, 1.5)
            .fromTo('.hero .marquee', {opacity: 0}, {opacity: 1, duration: 1}, 2)
    }


    // titles animation

    if (document.querySelector('.gsap-title')) {

        gsap.utils.toArray('.gsap-title').forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: '+100px bottom',
                },
                y: 100,
                opacity: 0,
                duration: 0.5,
            })

        })
    }

    if (document.querySelector('.gsap-y-100')) {

        gsap.utils.toArray('.gsap-y-100').forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: '+100px bottom',
                },
                y: 100,
                opacity: 0,
                duration: 1,
            })

        })
    }

    if (document.querySelector('.gsap-y-50')) {

        gsap.utils.toArray('.gsap-y-50').forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: '+150px bottom',
                },
                y: 50,
                opacity: 0,
                duration: 1,
            })
        })
    }

    if (document.querySelector('.gsap-y-30')) {

        gsap.utils.toArray('.gsap-y-30').forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: `-100px bottom`,
                },
                y: 300,
                opacity: 0,
                duration: 1,
            })
        })
    }

    if (document.querySelector('.gsap-y-20')) {

        gsap.utils.toArray('.gsap-y-20').forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: `top bottom`,
                },
                y: 200,
                opacity: 0,
                duration: 1,
            })
        })
    }

    if (document.querySelector('.gsap-image')) {

        let mediaImage = gsap.matchMedia();

        mediaImage.add("(min-width: 1023px)", () => {
            gsap.utils.toArray('.gsap-image').forEach(item => {
                gsap.to(item.querySelector('img'), {
                    scrollTrigger: {
                        trigger: item,
                        start: '-200px bottom',
                        scrub: true,
                    },
                    yPercent: 40,
                    ease: 'none',
                })
            })
        })
    }

    // request section animation

    if (document.querySelector('.requests__item')) {

        gsap.from('.requests__item', {
            scrollTrigger: {
                trigger: '.requests__item',
                start: '+50px bottom',
            },
            y: 150,
            opacity: 0,
            duration: 0.5,
            stagger: 0.1
        })
    }

    //approaches section animation

    if (document.querySelector('.approaches')) {

        gsap.from('.approaches__description p', {
            scrollTrigger: {
                trigger: '.approaches__description p',
                start: 'top bottom',
            },
            y: 200,
            opacity: 0,
            stagger: .2,
            duration: 0.5,
        })

        gsap.from('.approaches__item:nth-child(1)', {
            scrollTrigger: {
                trigger: '.approaches__item',
                start: '+200px bottom',
            },
            opacity: 0, scale: 0.5, transformOrigin: 'center center'
        })
        gsap.from('.approaches__item:nth-child(2)', {
            scrollTrigger: {
                trigger: '.approaches__item',
                start: '+200px bottom',
            },
            opacity: 0, scale: 0.5, transformOrigin: 'center center', delay: 0.3
        })
        gsap.from('.approaches__item:nth-child(3)', {
            scrollTrigger: {
                trigger: '.approaches__item',
                start: '+200px bottom',
            },
            opacity: 0, scale: 0.5, transformOrigin: 'center center', delay: 0.9
        })
        gsap.from('.approaches__item:nth-child(4)', {
            scrollTrigger: {
                trigger: '.approaches__item',
                start: '+200px bottom',
            },
            opacity: 0, scale: 0.5, transformOrigin: 'center center', delay: 0.6
        })

    }


    // consultaion section animation

    if (document.querySelector('.consultation')) {

        gsap.from('.consultation__decoration', {
            scrollTrigger: {
                trigger: '.consultation__decoration',
                start: '-100% bottom',
                end: '100% top',
                scrub: true,
            },
            rotate: 180,
            ease: 'none',
        })

    }
    // advantages section animation

    if (document.querySelector('.advantages')) {

        // const advantageImgAnimArea = window.innerHeight + document.querySelector('.advantages__image')?.getBoundingClientRect().height
        // gsap.to('.advantages__image', {
        //     scrollTrigger: {
        //         trigger: '.advantages__image',
        //         start: '+10% bottom',
        //         end: `+=${advantageImgAnimArea}px`,
        //         scrub: true,
        //     },
        //     yPercent: -30,
        // })


        gsap.utils.toArray('.advantages__item').forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: '+200px bottom',
                },
                xPercent: -100,
                duration: 1,
            })

        })
    }

    // mentor section animation

    if (document.querySelector('.mentor')) {

        gsap.from('.mentor__image', {
            scrollTrigger: {
                trigger: '.mentor__image',
                start: '+200px bottom',
            },
            scale: 0,
            transformOrigin: 'center center',
            opacity: 0,
            duration: 1,
        })
    }

    // reviews section animation

    if (document.querySelector('.reviews')) {

        gsap.from('.reviews__swiper-desk-prev', {
            scrollTrigger: {
                trigger: '.reviews__swiper-desk-prev',
                start: '+200px bottom',
            },
            scale: 0,
            transformOrigin: 'center center',
            opacity: 0,
            duration: 0.5,
        })

        gsap.from('.reviews__swiper-desk-next', {
            scrollTrigger: {
                trigger: '.reviews__swiper-desk-next',
                start: '+200px bottom',
            },
            scale: 0,
            transformOrigin: 'center center',
            opacity: 0,
            duration: 0.5,
        })

    }

    // directions section animation

    if (document.querySelector('.directions')) {

        gsap.from('.directions__first-decoration', {
            scrollTrigger: {
                trigger: '.directions__first-decoration',
                start: '-100% bottom',
                end: '100% top',
                scrub: true,
            },
            rotate: 180,
            ease: 'none',
        })

        gsap.from('.directions__second-decoration', {
            scrollTrigger: {
                trigger: '.directions__second-decoration',
                start: '-100% bottom',
                end: '100% top',
                scrub: true,
            },
            rotate: 180,
            ease: 'none',
        })

        gsap.to('.directions__second-decoration', {
            scrollTrigger: {
                trigger: '.directions__second-decoration',
                start: '-20% center',
                end: '+=700px',
                scrub: true,
            },
            scale: 1.5,
            xPercent: 35,
            yPercent: 35,
        })

        gsap.from('.directions__third-decoration', {
            scrollTrigger: {
                trigger: '.directions__third-decoration',
                start: '-100% bottom',
                end: '100% top',
                scrub: true,
            },
            rotate: 180,
            ease: 'none',
        })

        gsap.to('.directions__third-decoration', {
            scrollTrigger: {
                trigger: '.directions__second-decoration',
                start: '-20% center',
                end: '+=700px',
                scrub: true,
            },
            scale: 1.3,
            xPercent: 90,
            yPercent: 40,
        })

    }

    //posts section animation

    if (document.querySelector('.posts')) {

        gsap.from('.posts__swiper-desk-prev', {
            scrollTrigger: {
                trigger: '.posts__swiper-desk-prev',
                start: '+200px bottom',
            },
            scale: 0,
            transformOrigin: 'center center',
            opacity: 0,
            duration: 0.5,
        })

        gsap.from('.posts__swiper-desk-next', {
            scrollTrigger: {
                trigger: '.posts__swiper-desk-next',
                start: '+200px bottom',
            },
            scale: 0,
            transformOrigin: 'center center',
            opacity: 0,
            duration: 0.5,
        })
    }

// COURSE PAGES

    if (document.querySelector('.hero-course')) {

        const tlCourse = gsap.timeline();
        tlCourse.fromTo('.hero-course__title', {opacity: 0}, {opacity: 1, duration: 1}, .5)
            .fromTo('.hero-course__description > p', {yPercent: 100, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1}, 1)
            .fromTo('.hero-course__btn', {yPercent: 20, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1}, 2)
            .fromTo('.hero-course__items', {yPercent: 20, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1}, 2)
            .fromTo('.hero-course__item', {scale: .5, opacity: 0}, {scale: 1, opacity: 1, duration: 1, stagger: .2,}, 2.5)

    }

    // program section

    if (document.querySelector('.program')) {

        gsap.utils.toArray('.program__item').forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: 'top bottom',
                },
                xPercent: -100,
                duration: 1,
            })
        })

        // gsap.to('.program__image', {
        //     scrollTrigger: {
        //         trigger: '.program',
        //         start: '+100px bottom',
        //         end: '100%',
        //         scrub: true,
        //     },
        //     yPercent: 10,
        //     ease: 'none'
        // })
        //
        // gsap.to('.program__items', {
        //     scrollTrigger: {
        //         trigger: '.program',
        //         start: '+100px bottom',
        //         end: '100%',
        //         scrub: true,
        //     },
        //     yPercent: -10,
        //     ease: 'none'
        // })

    }

    // teach section

    if (document.querySelector('.teach')) {

        gsap.utils.toArray('.teach__item').forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: '150px bottom',
                },
                y: 50,
                opacity: 0,
            })
        })

    }

    if (document.querySelector('.faq')) {
        gsap.utils.toArray('.faq__item').forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: '150px bottom',
                },
                y: 50,
                opacity: 0,
                stagger: 1,
            })
        })
    }

    if (document.querySelector('.modules')) {
        gsap.utils.toArray('.modules__item').forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: '+200px bottom',
                },
                xPercent: -100,
                duration: 1,
            })
        })
    }
    if (document.querySelector('.individual')) {
        gsap.utils.toArray('.individual__item').forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: '150px bottom',
                },
                y: 100,
                opacity: 0,
            })
        })
    }

    if (document.querySelector('.why')) {
        gsap.utils.toArray('.why__item').forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: '+200px bottom',
                },
                xPercent: -100,
                duration: 1,
            })
        })
    }

// ABOUT SCHOOL PAGE

    if (document.querySelector('.philosophy')) {
        gsap.utils.toArray('.philosophy__item').forEach(item => {
            if (window.innerWidth >= 768) {
                gsap.from(item.querySelector('.gsap-image'), {
                    scrollTrigger: {
                        trigger: item.querySelector('.gsap-image'),
                        start: '+200px bottom',
                    },
                    scale: 0.5,
                    opacity: 0,
                    duration: 1,
                })

                gsap.from(item.querySelector('.philosophy__item-desc'), {
                    scrollTrigger: {
                        trigger: item.querySelector('.gsap-image'),
                        start: '+200px bottom',
                    },
                    y: 200,
                    opacity: 0,
                    duration: 1,
                })
            } else  {
                gsap.from(item.querySelector('.gsap-image'), {
                    scrollTrigger: {
                        trigger: item.querySelector('.gsap-image'),
                        start: 'top bottom',
                    },
                    scale: 0.5,
                    opacity: 0,
                    duration: 1,
                })

                gsap.from(item.querySelector('.philosophy__item-desc'), {
                    scrollTrigger: {
                        trigger: item.querySelector('.philosophy__item-desc'),
                        start: 'top bottom',
                    },
                    y: 200,
                    opacity: 0,
                    duration: 1,
                })
            }
        })

    }

// Blog Page

    if (document.querySelector('.blog-section')) {
        const tl = gsap.timeline();
        tl.fromTo('.hero__title', {opacity: 0}, {opacity: 1, duration: 1}, .5)
            .fromTo('.hero__description > p', {yPercent: 100, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1}, 1)
            .fromTo('.blog__items', {y: 100, opacity: 0}, {y: 0, opacity: 1, duration: 1}, 1.5)
    }

// ERROR PAGE

    if (document.querySelector('.error')) {
        gsap.from('.error__title', {
            scrollTrigger: {
                trigger: '.error',
                start: 'top bottom',
            },
            y: 100,
            opacity: 0,
            duration: 1,
        })
        gsap.from('.error__description', {
            scrollTrigger: {
                trigger: '.error',
                start: 'top bottom',
            },
            y: 100,
            opacity: 0,
            duration: 1,
        })
        gsap.from('.error__btn', {
            scrollTrigger: {
                trigger: '.error',
                start: 'top bottom',
            },
            y: 100,
            opacity: 0,
            duration: 1,
        })
    }

}


